import React, { useState, useEffect } from "react";
import { textileData } from '../../lib/textile_data';
import './content.css';
// import img1 from '../../images/dresses/dress1/img1.jpg'

const Content = () => {

    const [ dummyData, setDummyData ] = useState([]);

    useEffect(() => {
        setDummyData(textileData);
    },[])

    const displayAll = (data) => {

        // const imageArray = (items) => {
        //     return items?.map((item,i) => {
        //         return(
        //             <div
        //                 key={i}
        //             >
        //                 <img src={item.src} alt="" />
        //             </div>
        //         )
        //     })
        //     // for (let i = 0; i < item.length; i++){
        //     //         console.log(item);
        //     //     }
        // }

        return data.map((item, i) => {
            return (
                <div className="imageTextContainer" key={i}>
                    <div className="imgContainer">
                        <img src={item.img} alt="fashion creation" />
                    </div>
                    <p>{item.description}</p>
                    {/* {imageArray(item.images)} */}
                </div>
            )
        })
    }

    return (
        <div className="contentMainOuterContainer">
            <h3>Products</h3>
            <div className="contentMainInnerContainer">
                {displayAll(dummyData)}
            </div>
        </div>
    )
}

export default Content;


// import imageImport from './image.png';

// export const example = [
//   {
//     "name": "example",
//     "image": imageImport
//   }
// ]