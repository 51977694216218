import './App.css';
// import img1 from './images/img1.jpg'
// import img2 from './images/img2.jpg';
import Landing from './components';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src= {img2} className="img-logo " alt="logo" /> */}
        {/* <h2>Store Creation. Coming soon</h2> */}
        {/* <p>By - Fahmi Fatima</p> */}
      </header>
      <Landing/>
    </div>
  );
}

export default App;

// https://www.fashioncreations.in/