import React from 'react';
import './footer.css';
import instagramicon from '../../images/logo/instagramIcon.png';
import img2 from '../../images/logo/img2.jpg';

const Footer = () => {

    return (
        <div className="footerOuterComponent">
            <div className="footerInnerComponent">
                <div className="logo-container">
                        <img src={img2} alt="fashion creations" /> 
                </div>
                <div className="idComponent">
                    <div className="headerComponent">
                        <h3>Contact Us</h3>
                    </div>
                    <div className="detailComponent">
                        <p>Clothing (brand)</p>
                        <p>Fashion Creation</p>
                        <p>Women's Clothing Store</p>
                        <p>For Queries/orders</p>
                        <p>WhatsApp - 6387550995</p>
                    </div>
                </div>
                <div className="idComponent">
                    <div className="headerComponent">
                        <h3>Email id.</h3>
                    </div>
                    <div className="detailComponent">
                        <p>fahmifatima@fashioncreations.in</p>
                    </div>
                </div>
                <div className="idComponent">
                    <div className="headerComponent">
                        <h3>Social Media</h3>
                    </div>
                    <div className="detailComponent">
                        <a href="https://www.instagram.com/fahmi_fatima4/">
                            <img src={instagramicon} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;