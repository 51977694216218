// import Img from '../images/dresses/dress1';
// import Img from '../../../images/dress3'
// import Img from '../../public/dress1/img1.jpg'

export const textileData = [
    {   
        id: 1,
        shirt: "Rawsilk",
        slip: "silk",
        pant: "Glace Cotton",
        dupatta: "chiffon Pure",
        color: "Ocean green",
        description: "The sheer ocean green blends with the remarkable dark emerald to boost the summer energy, paired with straight pant fabric and a two toned organza dupatta",
        img: require('../images/dresses/dress1/img1.jpg'),
        images: [
            {src: require('../images/dresses/dress1/img1.jpg')},
            {src: require('../images/dresses/dress1/img2.jpg')},
            {src: require('../images/dresses/dress1/img3.jpg')},
            {src: require('../images/dresses/dress1/img4.jpg')},
            {src: require('../images/dresses/dress1/img5.jpg')},
            {src: require('../images/dresses/dress1/img6.jpg')},
            {src: require('../images/dresses/dress1/img7.jpg')}
        ]
    },
    {
        id: 2,
        shirt: "Cotton mix",
        slip: "",
        pant: "cotton",
        dupatta: "Heavy geogett",
        color: "Royal blue",
        description: "A Neavy blue cotton shirt with loop buttons infront & heavy embellishment of luxury stone, moti, pearls & curtdana.",
        img: require('../images/dresses/dress2/img2.jpg'),
    },
    {
        id: 3,
        shirt: "Tissue",
        slip: "silk",
        pant: "Rawsilk",
        dupatta: "Wrinkled chiffon",
        color: "Light Beige",
        description: "The dynamic cream dress comes with flower base trouser a stole dupatta enhanced the look & top made with scoop neckline & sheer bell sleeves.",
        img: require('../images/dresses/dress3/img3.jpg')
    },
    {
        id: 4,
        shirt: "",
        slip: "",
        pant: "",
        dupatta: "",
        color: "",
        description: "Go beyond basic fashion create something remarkable for yourself with FC - Geometries never go out of fashion neither does our geometric designs on cotton fabric with dyed organja Dupatta",
        img: require('../images/dresses/dress4/img1.jpg')
    },
    {
        id: 5,
        shirt: "",
        slip: "",
        pant: "",
        dupatta: "",
        color: "",
        description: "Delivers stunning black stripe shirt with beautiful apllique embroidery. The splendid raw silk creame dupatta partywear collection.",
        img: require('../images/dresses/dress5/img6.jpg')
    },
    {
        id: 6,
        shirt: "Pure organza",
        slip: "Organza",
        pant: "Glace silk",
        dupatta: "Georgette",
        color: "Snow White",
        description: "White snow collection is composed of appealingly white organja shirt enchanced with an embellished neckline & style beautifully with lace applique. With stunning red organja dupatta is accentuated with marvelous look & making the whole outfit steller piece!!",
        img: require('../images/dresses/dress6/img5.jpg')
    },
    {
        id: 7,
        shirt: "Organza",
        slip: "Organza silk",
        pant: "Pure cotton",
        dupatta: "",
        color: "Beige",
        description: "The gracefull solid beige raw silk long shirt embeded with luxury beads. With multi dyed dupatta. A flush of opulent beige hues & intricate minimal embellishment. Showcasing the statement look for this seasonal gathering.",
        img: require('../images/dresses/dress7/img1.jpg')
    }
]