import React from 'react';
import './header.css';
import header from '../../images/logo/header.png';

const Header = () => {

    return (
        <div className="headerOuterComponent">
            <div className="headerInnerComponent">
                <div className="imageComponent">
                    <img src={header} alt="fashion creations" />
                </div>
                <div className="detailsComponent">
                    <h3>Fashion Creation</h3>
                    <p>it's all about the dress</p>
                    <p>By - Fahmi Fatima</p>
                </div>
            </div>
        </div>
    )

}

export default Header;