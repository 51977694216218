import React from "react";
import './index.css';
// import Navbar from "./navbar/Navbar";
import Content from "./content/Content";
import Deck from "./slider/deck";
import Footer from "./footer/footer";
import Header from "./header/header";

const Landing = () => {
    return (
        <div className="landing-main-outer-container">
            {/* <Navbar/> */}
            <Header />
            <Deck />
            <Content/>
            <Footer />
        </div>
    )
}

export default Landing;